var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('draggable',_vm._b({staticClass:"list-group",attrs:{"disabled":!_vm.allows_crud,"group":"question","handle":".handle"},model:{value:(_vm.questions),callback:function ($$v) {_vm.questions=$$v},expression:"questions"}},'draggable',_vm.dragOptions,false),[_c('transition-group',{attrs:{"type":"transition","name":"flip-list"}},[_vm._l((_vm.questions),function(question){return _c('div',{key:`question-dragable-${question.id}-${question.test}-${question.question_type}`,staticClass:"container-question"},[_c('NewQuestionCard',{ref:`question-dragable-${question.id}-${question.test}-${question.question_type}`,refInFor:true,attrs:{"container_instrument_id":_vm.container_instrument_id,"allows_crud":_vm.allows_crud,"Question":question,"isLoading":_vm.isLoading,"evaluation_criteria_micro_ids":_vm.evaluation_criteria_micro_ids,"preview_evaluation":_vm.preview_evaluation,"checked_score":_vm.checked_score,"checked_weightning":_vm.checked_weightning,"evaluatee_view":_vm.evaluatee_view,"evaluator_view":_vm.evaluator_view,"is_running_test":_vm.is_running_test,"finished_test":_vm.finished_test,"user_id":_vm.user_id,"evaluation_situations":_vm.evaluation_situations,"closedEvaluationScore":_vm.closedEvaluationScore,"is_published":_vm.is_published}})],1)})],2)],1),_c('div',{staticClass:"container-buttons mb-2"},[(
        _vm.allows_crud &&
        _vm.test &&
        !_vm.test.question_creation_is_locked &&
        (!_vm.evaluationSituation ||
          (_vm.evaluationSituation &&
            ![
              'duoc_ejecucion_practica_test',
              'duoc_entrega_de_encargo_test',
              'duoc_presentacion_test',
            ].includes(_vm.evaluationSituation.internal_use_id) &&
            _vm.evaluationSituation.enable_questions &&
            (_vm.evaluationSituation.enable_redaction_questions ||
              _vm.evaluationSituation.enable_simple_selection_questions ||
              _vm.evaluationSituation.enable_multiple_selection_questions ||
              _vm.evaluationSituation.enable_matching_questions)))
      )?_c('b-button',{staticClass:"mt-2",attrs:{"variant":"primary","size":"sm"},on:{"click":_vm.openModalCreateQuestion}},[_vm._v(" + Agregar Pregunta "),(_vm.segment_id)?_c('span',[_vm._v("dentro del "+_vm._s(_vm.$getVisibleNames("evaluations2.testsegment", false, "Segmento")))]):_vm._e()]):_vm._e()],1),_c('b-modal',{attrs:{"id":`modal-created-question-${_vm.instrument_id}-${_vm.segment_id}-${_vm.container_instrument_id}`,"title":`Crear Pregunta`,"hide-footer":"","size":"lg","no-enforce-focus":""},on:{"hide":_vm.hideModalQuestion}},[_c('NewQuestionForm',{attrs:{"test_id":_vm.instrument_id,"segment_id":_vm.segment_id,"evaluation_criteria_micro_ids":_vm.evaluation_criteria_micro_ids,"order":_vm.questions.length > 0 ? _vm.questions[_vm.questions.length - 1].order + 1 : 1,"evaluation_situations":_vm.evaluation_situations},on:{"closeModal":function($event){return _vm.$bvModal.hide(
          `modal-created-question-${_vm.instrument_id}-${_vm.segment_id}-${_vm.container_instrument_id}`
        )}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }